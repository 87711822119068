import {camelCase, snakeCase} from 'lodash';
/**
 * Takes an object and runs all keys contained in the object against the
 * given transformer
 *
 * This function should not be exported, use camelizeKeys or snakeCaseKeys
 *
 * @param obj any object of type A
 * @param transformer a function that will transform the key strings
 * @returns an object of type T, which is A with the keys transformed
 */
const transformKeys = <A extends object, T extends object>(
  obj: A,
  transformer: (str: string) => string
): T => {
  const ret = {};

  for (const [key, value] of Object.entries(obj)) {
    if (value instanceof Array) {
      ret[transformer(key)] = transformArray(value, transformer);
    } else if (value instanceof Object) {
      ret[transformer(key)] = transformKeys(value, transformer);
    } else {
      ret[transformer(key)] = value;
    }
  }

  return ret as T;
};

/**
 * Takes an array and runs all keys nested in the array against the
 * given transformer
 *
 * This function should not be exported, use camelizeKeys or snakeCaseKeys
 *
 * @param obj any array of type A
 * @param transformer a function that will transform the key strings
 * @returns an array of type T, which is A with the keys transformed
 */
const transformArray = <A, T>(
  arr: Array<A>,
  transformer: (str: string) => string
): Array<T> => {
  const ret = [];

  for (const value of arr) {
    if (value instanceof Array) {
      ret.push(transformArray(value, transformer));
    } else if (value instanceof Object) {
      ret.push(transformKeys(value as Object, transformer));
    } else {
      ret.push(value);
    }
  }

  return ret as T[];
};

/**
 * Given an object `obj` transforms all the keys (including nested
 * objects and arrays) into camelCase format
 *
 * @param obj any object
 * @returns obj with the keys transformed to camelCase
 */
export const camelizeKeys = <T extends object>(
  obj: T
): SnakeToCamelCaseKeys<T> => {
  if (obj instanceof Array) {
    return transformArray(obj, camelCase) as SnakeToCamelCaseKeys<T>;
  }
  return transformKeys<T, SnakeToCamelCaseKeys<T>>(obj, camelCase);
};

/**
 * Given an object `obj` transforms all the keys (including nested
 * objects and arrays) into snake case format
 *
 * @param obj any object
 * @returns obj with the keys transformed to snake case
 */
export const snakeCaseKeys = <T extends object>(
  obj: T
): CamelToSnakeCaseKeys<T> => {
  if (obj instanceof Array) {
    return transformArray(obj, snakeCase) as CamelToSnakeCaseKeys<T>;
  }
  return transformKeys<T, CamelToSnakeCaseKeys<T>>(obj, snakeCase);
};

//  https://stackoverflow.com/questions/60269936/typescript-convert-generic-object-from-snake-to-camel-case
type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
  : S;

type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnakeCase<U>}`
  : S;

export type SnakeToCamelCaseKeys<T> = T extends Array<infer R>
  ? Array<SnakeToCamelCaseKeys<R>>
  : T extends object
  ? {
      [K in keyof T as SnakeToCamelCase<K & string>]: T[K] extends Array<
        infer R
      >
        ? Array<SnakeToCamelCaseKeys<R>>
        : T[K] extends object
        ? SnakeToCamelCaseKeys<T[K]>
        : T[K];
    }
  : T;

export type CamelToSnakeCaseKeys<T> = T extends Array<infer R>
  ? Array<CamelToSnakeCaseKeys<R>>
  : T extends object
  ? {
      [K in keyof T as CamelToSnakeCase<K & string>]: T[K] extends Array<
        infer R
      >
        ? Array<CamelToSnakeCaseKeys<R>>
        : T[K] extends object
        ? CamelToSnakeCaseKeys<T[K]>
        : T[K];
    }
  : T;

/** Intersection the types T & U when there is a key conflict use the definition from U */
export type OverrideKeys<T, U> = U & Omit<T, keyof U>;

export const camelCaseToWords = str => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
