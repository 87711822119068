export const splitName = (name: string, fill = true): Array<string> => {
  if (!name && !fill) return Array(2);
  if (!name) return ['', ''];
  const nameArray = (name || '')
    .split(' ')
    .map(str => str.trim())
    .filter(str => str !== '');
  const firstName = nameArray[0];
  const lastName = nameArray.slice(1).join(' ');
  return [firstName, lastName];
};
